.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    -webkit-animation: App-logo-spin infinite 20s linear;
  }
}

.djoRoX {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.py-header {
  padding: 10px 0px;
}

.header-btn {
  width: 192px;
  height: 46px;
  font-family: helvetica !important;
  font-size: 14px !important;
}

.navbar {
  padding: 20px 21px !important;
}

.side-icon {
  font-size: 1.2rem;
}

.side-right {
  position: absolute;
  right: 0%;
  display: flex;
  height: 100vh;
  overflow: scroll initial;
}

.head-div {
  padding: 24px 14px !important;
}

.konzrO > .toggled {
  padding: 24px 29px !important;
}

.konzrO .head-div .icon-suffix {
  margin: 0px 7px;
  cursor: pointer;
}

.konzrO .head-div .head-text {
  margin: 0px 0px;
}

/* Font Color */

.pointer {
  cursor: pointer;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  color: black !important;
  opacity: 1;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #81b441 !important;
  opacity: 1;
}

.Mui-focused {
  color: #81b441 !important;
}

.text-dark-bb > label,
.Mui-focused,
#standard-basic-label {
  color: #1d4354 !important;
  opacity: 1;
  font-weight: 600;
  /* font: normal normal bold 16px/25px Helvetica; */
}

.text-dark-b {
  color: #1d4354 !important;
  opacity: 1;
  font-weight: 600;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before,
.css-1480iag-MuiInputBase-root-MuiInput-root:after,
.css-1wt0ykv:hover:not(.Mui-disabled)::before,
.css-1wt0ykv::after {
  border-bottom: 1px solid #eceff37d !important;
  opacity: 0.8 !important;
}

.accordian > div {
  min-width: auto !important;
  min-height: auto !important;
  box-shadow: 0px 3px 5px #0000001a;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.accordian > div > div > div:first-child {
  margin: 0 !important;
}

.accordian > div > div {
  min-height: auto !important;
}
.accord-hov:hover,
.accord-hov:hover > svg > * > *,
.accord-hov:hover > svg > * > * > *,
.accord-hov:hover > svg > * > * > * > *,
.accord-hov:hover > svg > * > * > * > * > * {
  color: #78b94e !important;
  fill: #78b94e !important;
}
.mar-grad > div > div::before,
.mar-grad > div > div::after {
  background: transparent !important;
}
.contact-btn {
  width: 230px;
  height: 60px;
  font: normal normal normal 16px/25px Helvetica;
  border-radius: 64px;
}

.regular-checkbox + label {
  background-color: #fff;
  border: 1px solid #707070;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 8px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.big-checkbox:checked + label:after {
  font-size: 28px;
  left: 6px;
}

.regular-checkbox:checked + label:after {
  content: "\2714";
  font-size: 14px;
  position: absolute;
  top: -4px;
  left: 2px;
  color: #14a800;
}

input[type="radio"] {
  border: 1px solid #fff;
  padding: 0.5em;
  -webkit-appearance: none;
}

input[type="radio"]:checked {
  background: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///////yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat center
    center;
  background-size: 9px 9px;
}

input[type="radio"]:focus {
  outline-color: transparent;
}

.text-dark {
  color: #000 !important;
}

.darker {
  color: #1d4354;
}

.inner-h > .carousel-inner {
  /* min-height: auto !important; */
}

.carousel-item {
  position: relative !important;
}

.explore {
  color: #627792;
}

.para-d {
  color: #515151;
  font-family: helvetica;
}

.lh-15 {
  line-height: 1.5 !important;
}
.bg-light {
  background-color: #f3f6f9 !important;
}
.lh-13 {
  line-height: 1.3 !important;
}

.no-radius {
  border-radius: 0 !important;
  -webkit-border-radius: 0 !;
  -moz-border-radius: 0 !;
  -ms-border-radius: 0 !;
  -o-border-radius: 0 !;
}

.light-grey {
  color: ECECEC;
}

.conter-light {
  color: #1d435466;
}

.lighter {
  color: #eceff3 !important;
}

.lh-33 {
  line-height: 1.5 !important;
}

.lighter-hov:hover {
  color: #81b441 !important;
}

.grey {
  color: #ececec !important;
  opacity: 0.8;
}

.low-op {
  opacity: 0.8 !important;
}

.font-light > .font-sans {
  color: #eceff3d9;
}

.font-weight-light {
  font-weight: 500;
}

.font-clr-green {
  color: #81b441 !important;
}

.hov-btn-color > span {
  color: #81b441 !important;
}

.hov-btn-color:hover > span {
  color: white !important;
}

.parrot {
  color: #b0ee63 !important;
}

.navbar-nav > a {
  padding: 23px 9px !important;
}

.font-clr-blue {
  color: #1d4354 !important;
}

.bg-blue {
  background: #1d4354;
}
.bg-menu-header {
  background: #1d4354 !important;
}

.bg-blue-hov:hover {
  background: #81b441;
}

.sticky-top {
  background-color: white;
  box-shadow: 2px 5px 7px 1px #80808030;
}

/* Buttons to check Our Expertise */

.expertise-btn {
  background-color: transparent;
  border: 1px solid #215a77;
  border-radius: 30px;
  width: fit-content;
}

.services-btn {
  background: #14a800 !important;
  border: none;
  outline: none;
  border-radius: 30px;
  color: #fff;
}

.footer-btn {
  background-color: #14a800;
  font: normal normal normal 16px/25px Open Sans;
  border-radius: 64px;
}

.getin-btn {
  background-color: #b0ee63;
  font: normal normal normal 16px/25px Open Sans;
  border-radius: 64px;
  padding: 10px 0px !important;
}

.getin-btn:hover {
  background-color: #9bec37;
  font: normal normal normal 16px/25px Open Sans;
  border-radius: 64px;
  box-shadow: 0px 3px 31px #fffdfd3d;
}

.profile-btn {
  background-color: #1d4354;
  font: normal normal normal 16px/25px Open Sans;
  border-radius: 64px;
  padding: 10px 0px !important;
}

.profile-btn:hover {
  background-color: #042736;
  font: normal normal normal 16px/25px Open Sans;
  border-radius: 64px;
  box-shadow: 0px 3px 31px #0404043d;
}

.footer-btn:hover {
  background-color: #215819;
}

.services-btn:active {
  border: none;
  outline: none;
}

.services-btn:focus {
  border: none;
  outline: none;
}

.industies-btn {
  color: #1d4354;
  background-color: transparent;
  border: none;
  outline: none;
}

.industies-btn:active {
  border: none;
  outline: none;
}

.shadow {
  box-shadow: 0px 3px 62px #00000029;
}

.upper {
  text-transform: uppercase;
}
.mt--5 {
  margin-top: -4%;
}
/* Cards CSS classes and properties.*/

.card-d {
  background-color: #f3f6f9;
  border-radius: 10px;
  color: #1d4354;
}

.card-d > p {
  color: #515151;
  font-family: helvetica;
}

.card-d > h5 {
  color: #1d4354;
  font-family: Open Sans;
}
.card-carousel {
  background-color: #f3f6f9;
  border-radius: 10px;
  color: #1d4354;
}

.card-carousel > p {
  color: #515151;
  font-family: helvetica;
}

.card-carousel > h5 {
  color: #1d4354;
  font-family: Open Sans;
}
.card-d:hover {
  background-color: unset !important;
  box-shadow: none;
}

.card-d-b:hover > p {
  color: #f5f5f5 !important;
}

.card-d-b:hover > button > div > * {
  color: #81b441 !important;
}

.card-d:hover > h5 {
  color: #1d4354;
}

.card-d:hover > p {
  color: #515151;
}

.card-carousel:hover {
  background-color: #1d4354 !important;
  box-shadow: none;
}

.card-carousel:hover > p {
  color: #f5f5f5 !important;
}

.card-carousel:hover > button > div > * {
  color: #b0ee63 !important;
}

.card-carousel:hover > h4 {
  color: #f5f5f5;
}
.card-carousel:hover > .overlay-box > svg > g > g > g > * {
  fill: #b0ee63 !important;
}

.card-carousel:hover > .overlay-box > svg > g > g > * {
  fill: #b0ee63 !important;
}

.card-carousel:hover > .overlay-box > svg > g > * {
  fill: #b0ee63 !important;
}

.card-carousel:hover > .overlay-box > svg > * {
  fill: #b0ee63 !important;
}
.overlay-box {
  position: relative;
  width: 30%;
  height: 100px;
}

.card-d:hover > .overlay-box > svg > g > g > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box > svg > g > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box > svg > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box > svg > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box1 > svg > g > g > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box1 > svg > g > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box1 > svg > g > * {
  fill: #253858 !important;
}

.card-d:hover > .overlay-box1 > svg > * {
  fill: #253858 !important;
}

.card-d:hover .overlay-box {
  position: relative;
  width: 30%;
  transform: rotate(5deg);
  display: block;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
}

.map-bg {
  background-image: url("../../assets/map-ft/map-ft.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}
.webbg {
  background-image: url("../../assets/expertise/webbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  padding-top: 50px;
  padding-bottom: 150px;
  position: relative;
}
.casestudy-banner {
  background-image: url("../../assets/expertise/webbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  padding: 70px 0px;
}
.web-solution {
  top: -90px;
  border-radius: 20px;
}
.carousel-indicators {
  bottom: -13% !important;
}
.card-d {
  margin: auto;
  margin-top: 5% !important;
}
.expertise-card {
  margin: 0px 10px !important;
}
.overlay-box img {
  height: 100%;
  width: 100%;
}

.overlay-device {
  position: relative;
  width: 26%;
  height: 21%;
}

.card-d:hover > .overlay-device > svg > g > g > * {
  fill: #b0ee63 !important;
}

.card-d:hover .overlay-device {
  position: relative;
  width: 26%;
  height: 21%;
  transform: rotate(5deg);
  display: block;
}

.overlay-device img {
  height: 100%;
  width: 100%;
}

.overlay-cloud {
  position: relative;
  width: 26%;
  height: 21%;
}

.card-d:hover > .overlay-cloud > svg > g > * {
  fill: #b0ee63 !important;
}

.card-d:hover .overlay-cloud {
  position: relative;
  width: 26%;
  height: 21%;
  transform: rotate(5deg);
  transition-duration: unset;
  animation-iteration-count: infinite;
  display: block;
}

.overlay-cloud img {
  height: 100%;
  width: 100%;
}

.card-d-btn {
  border: none;
  outline: none;
  background-color: transparent;
  color: #1d4354;
  width: fit-content;
  transition: 0.25s;
  font: inherit;
  /* line-height: 1;
  padding-top: 1em; */
  padding: 20px 35px;
}

/* card-d-btn fill up */

.op {
  opacity: 1;
}

.upcard:hover > .op {
  display: none;
}

.afterup {
  display: none;
}
.display-card {
  display: grid;
  justify-content: center;
  align-items: center;
}
.upcard:hover > .afterup {
  display: block;
  transition: display 2s linear;
  -webkit-transition: display 2s linear;
  -moz-transition: display 2s linear;
  -ms-transition: display 2s linear;
  -o-transition: display 2s linear;
}

.card-d:hover .card-d-btn {
  color: #81b441;
}

.up:hover,
.up:focus {
  box-shadow: inset 0 -3em 0 0 var(--hover);
}

.up {
  --color: #e4cb58;
  --hover: #94e458;
}

.card-d-btn:hover,
.card-d-btn:focus {
  border-color: var(--hover);
  /* color: #fff !important; */
}

.v-align {
  vertical-align: middle;
  font-size: 1.3srem;
  font-weight: 600 !important;
}

.card-d:hover .card-d-btn {
  color: #b0ee63;
}

.upcard:hover > .upcardwhite {
  color: white !important;
}

.upcard:hover,
.upcard:focus {
  box-shadow: inset 0 -9.25em 0 0 var(--hover), 0px 3px 31px #0404043d;
}

.ex-card:hover,
.ex-card:focus {
  box-shadow: inset 0 -20.25em 0 0 var(--hover), 0px 3px 31px #04040400;
}

.upcard {
  --color: #e4cb58;
  --hover: #1d4354;
}
.ex-card-des {
  position: absolute;
  top: 0;
  left: 5%;
}
.card-d-btn:hover,
.card-d-btn:focus {
  border-color: var(--hover);
  /* color: #fff !important; */
}
.no-shade {
  box-shadow: none !important;
}
.v-align {
  vertical-align: middle;
  font-size: 1.3srem;
  font-weight: 600 !important;
}

/* Copied data from Original Digimark File */

@font-face {
  font-family: "helvetica";
  src: url(../../fonts/Helvetica-Font/Helvetica.ttf);
  color: #ffffff;
}

.underline {
  text-decoration: underline;
}

.text-dark {
  color: #153556 !important;
}

body {
  font-family: "helvetica" !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #c3ee8f;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.b-green {
  border: 1px solid #81b441 !important;
}
.white {
  color: #ffffff;
}

.border-solid {
  border-top: 5px solid white !important;
}

.number {
  position: absolute;
  top: 18%;
  right: 15%;
  font-size: 8rem;
  z-index: 0;
  line-height: 1;
  font-weight: bold;
  font-family: sans-serif;
  opacity: 0.06;
}

.relative {
  position: relative;
  z-index: 0;
}

.drop-down {
  box-shadow: 0px 3px 9px #00000029;
  border: 1px solid #aaaaaa1a;
  left: 1%;
  width: max-content;
}

.green-hov:hover {
  color: #81b441 !important;
  transition: color 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}
.bg-g-hov:hover {
  background-color: #81b441 !important;
}
.white-filter {
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
.b-parrot {
  border-color: #81b441 !important;
}
.bg-parrot {
  background-color: #81b441 !important;
}
.bg-g-hov:hover > img {
  filter: brightness(0) invert(1);
}

.white-hover:hover {
  color: rgb(212, 211, 211) !important;
  border-color: rgb(212, 211, 211) !important;
  transition: color 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}

.svg:hover > a > svg > * {
  fill: #b0ee63 !important;
  transition: fill 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-transition: fill 0.5s;
  -moz-transition: fill 0.5s;
  -ms-transition: fill 0.5s;
  -o-transition: fill 0.5s;
}

.green-hov-child:hover > a {
  color: #b0ee63 !important;
  transition: color 0.5s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  -ms-transition: color 0.5s;
  -o-transition: color 0.5s;
}

.font-sans {
  font-family: sans-serif;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
  font-weight: 600;
}

.lh-1 {
  line-height: 1 !important;
}

.mb--5 {
  margin-bottom: -15%;
}

.bg-light-v {
  background: #f3f6f9 !important;
}

.company-card-title {
  margin-top: -11%;
}

.text-trans {
  color: transparent !important;
}
.ex-cards {
  box-shadow: 0px 3px 39px #00000017;
  border-radius: 20px;
}
.br-tl-20 {
  border-top-left-radius: 20px;
}
.br-tr-20 {
  border-top-right-radius: 20px;
}
.br-bl-20 {
  border-bottom-left-radius: 20px;
}
.br-br-20 {
  border-bottom-right-radius: 20px;
}
.over-flow-auto {
  overflow: hidden !important;
}
.exp-accord > div {
  box-shadow: none !important;
}
.exp-accord p {
  font-size: 18px;
}
.gif-size {
  width: 85% !important;
}
.border-r-center::after {
  height: 10rem;
  position: absolute;
  right: 0;
  content: "";
  border-right: 1px solid #dee2e6 !important;
  top: 16%;
}
.bg-contact {
  background-image: url(../../assets/Group34.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: fit-content;
}

.bg-story {
  background-image: url(../../assets/Group\ 3947.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: fit-content;
}

.bg-ex-carousel {
  background-image: url(../../assets/expertise/exp_carousel.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: fit-content;
}

.bg-seconday {
  background-color: #1d4354 !important;
}

.secondary {
  color: #1d4354;
}

.helvetica {
  font-family: helvetica !important;
}

.sans {
  font-family: sans-serif;
}
@font-face {
  src: url("/src/fonts/OpenSans-ExtraBold.ttf");
  font-family: OpenSans-ExtraBold;
}
.open-sans {
  font-family: "Open Sans";
}

.navbar-expand-lg {
  padding-top: 0 !important;
  width: 91.666667%;
  margin: auto;
  background: #ffffff !important;
}

.w-fit {
  width: fit-content;
}

/* Sticky Header on Scroll */

.header {
  background: #555;
}

.hdr-link > a {
  text-decoration: none;
  color: #0e2b5c;
}

.hdr-link > a:hover {
  text-decoration: none;
  color: #82b541;
}

.f-link {
  font-size: 16px;
}

.content {
  padding-bottom: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

/* Tooltip show on hover in navbar option */

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 30vw;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  margin-left: -333%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.scroll-btn {
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 99999;
}

.arrow-btn {
  display: block;
  font-size: 22px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #81b441 !important;
  border-radius: 30px;
  text-align: center;
  transition: all 0.2s ease-in-out !important;
  -moz-transition: all 0.2s ease-in-out !important;
  -webkit-transition: all 0.2s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
  box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%) !important;
  -webkit-tap-highlight-color: transparent !important;
  -ms-transition: all 0.2s ease-in-out !important;
}

.arrow-btn:hover {
  background: #243858 !important;
}

.dropdown {
  border: 1px solid #aaaaaa1a;
  right: 37%;
  box-shadow: 0px 3px 9px #00000029;
}

/* Text Animation Infinite */

.content {
  overflow: hidden;
  font-family: "Lato", sans-serif;
  font-size: 35px;
  line-height: 1.2;
  color: #000;
}

.content__container {
  font-weight: 600;
  overflow: hidden;
  padding: 0;
}

.content__container__text {
  display: inline;
  float: left;
  margin: 0;
}

.content__container__list {
  line-height: 1;
  margin-top: 0;
  padding-left: 110px;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.content__container__list__item {
  line-height: 1.5;
  margin: 0;
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

/* Zoom-in Zoom-out animation Infinite Repeatition */

.zoom-in-zoom-out {
  margin: 24px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  animation: zoom-in-zoom-out 2s ease-out infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* Zoom-in Zoom-Out Button Hover animation */

.btn1:link,
.btn1:visited {
  text-decoration: none;
  display: inline-block;
  position: absolute;
}

.btn1:hover {
  transform: translateY(0px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn1:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.br-10 {
  border-radius: 10px;
}

.active-border {
  border: none !important;
  border-left: 2px solid #81b441 !important;
}

.un-fill > * {
  fill: #1d4354 !important;
}

.fill-green > * {
  fill: #81b441 !important;
}

.br-15 {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.br-20 {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.br-8 {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.btn1 {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;
  border: 1px solid white;
  font-size: 1rem;
  line-height: 1 !important;
  border-radius: 1.5rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.btn1::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn1:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn-animated {
  -webkit-animation: moveInBottom 5s ease-out;
  animation: moveInBottom 5s ease-out;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

@-webkit-keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Button Background Change Animation on hover */

.button--pan {
  font-weight: 700;
  border: 2px solid #000;
  border-radius: 3rem;
  overflow: hidden;
  color: #ffffff;
}

.bg-parrot {
  background: #81b441 !important;
}
.text-parrot {
  color: #81b441;
}
.bg-parrot:hover {
  background: #215819 !important;
}

.button {
  pointer-events: auto;
  cursor: pointer;
  background: #81b441 !important;
  border: none;
  padding: 1.5rem 1rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  color: #fff !important;
}

.button::before,
.button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button--pan:hover::before {
  transform: translate3d(100%, 0, 0);
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
}

.button--pan span {
  position: relative;
  /* mix-blend-mode: difference; */
}

.button--pan::before {
  content: "";
  background-color: #d9e9c8 !important;
  color: #82b541 !important;
  transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -webkit-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -moz-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -ms-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -o-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
}

.button--pan-1 {
  font-weight: 700;
  border: 2px solid #000;
  border-radius: 3rem;
  overflow: hidden;
  color: #ffffff;
}

.button-1 {
  pointer-events: auto;
  cursor: pointer;
  background: #81b441 !important;
  border: none;
  padding: 1.5rem 3rem;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  display: inline-block;
  color: #fff !important;
}

.button-1::before,
.button-1::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button--pan-1:hover::before {
  transform: translate3d(100%, 0, 0);
}

.button--pan-1 span {
  position: relative;
  /* mix-blend-mode: difference; */
}

.button--pan-1::before {
  content: "";
  background-color: #1d4354 !important;
  color: white !important;
  transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -webkit-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -moz-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -ms-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
  -o-transition: transform 0.2s cubic-bezier(0.7, 1, 0.2, 0);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a:hover {
  text-decoration: none !important;
}

body {
  background-repeat: no-repeat;
  margin: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.outline-none {
  outline: none !important;
}

.outline-none:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* .basic-multi-select > div {
  border: none !important;
  border-bottom: 1px solid #1d4354 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !;
  -moz-border-radius: 0 !;
  -ms-border-radius: 0 !;
  -o-border-radius: 0 !;
  box-shadow: none !important;
} */
.select__control {
  border: none !important;
  border-bottom: 1px solid #1d4354 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin-top: 12px !important;
}
.select__control--is-focused {
  border-color: #14a800 !important;
}

/* .basic-multi-select > div > div:first-child > div:nth-child(-n + 3) {
  /* background: #ffff !important; */
/* box-shadow: 0px 3px 6px #00000029; */
/* } */

.basic-multi-select > div > div:first-child > div:nth-child(-n + 3) {
  /* background: #ffff !important; */
  color: #14a800 !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !;
  -moz-border-radius: 5px !;
  -ms-border-radius: 5px !;
  -o-border-radius: 5px !;
}

.basic-multi-select > div > div:first-child > div:nth-child(-n + 3) > div:first-child {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  line-height: 1 !important;
}

.basic-multi-select > div > div {
  padding: 0 !important;
}

#react-select-3-placeholder {
  color: #1d4354 !important;
  opacity: 1;
  /* Firefox */
  font: normal normal bold 13px/25px Helvetica !important;
}

.float-label > input {
  border: none !important;
  border-bottom: 1px solid #1d4354 !important;
  opacity: 1;
  border-radius: 0 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 0 !important;
}
.footer-float-label::placeholder {
  color: white !important;
  opacity: 0.8;
}
.footer-float-label > input {
  color: white !important;
  border: none !important;
  border-bottom: 2px solid #f3f6f9 !important;
  opacity: 0.8;
  line-height: 2.5 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  padding: 0 !important;
}

.floating-label > label {
  padding-left: 0 !important;
  letter-spacing: 0px;
  font-size: 16px !important;
  font-weight: 800;
  color: #1d4354 !important;
  padding-bottom: 13px;
  opacity: 1;
  font-weight: light !important;
}
.floating-label {
  margin-top: 25px !important;
}
.footer-float-label > label {
  padding-left: 0 !important;
  letter-spacing: 1px;
  color: #ffffff !important;
  opacity: 0.8;
}
.subscribe {
  position: absolute;
  right: 3%;
  bottom: 25%;
  font-size: 13px;
}
.floating-label > input:focus {
  opacity: 1 !important;
  box-shadow: none !important;
  border-color: #81b441 !important;
}

input:focus ~ label {
  color: #81b441 !important;
}

.floating-label:focus-within > label,
.floating-label > .floating-label-active {
  transform: translate(0, -60%) scale(0.8) !important;
  -webkit-transform: translate(0, -60%) scale(0.8) !important;
  -moz-transform: translate(0, -60%) scale(0.8) !important;
  -ms-transform: translate(0, -60%) scale(0.8) !important;
  -o-transform: translate(0, -60%) scale(0.8) !important;
}

.upload-btn-wrapper {
  border: 3px dashed #707070;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* Changing in the link of Now Hiring */

.h-n-link {
  font-size: 1rem;
  text-decoration: underline;
  color: #1d4354;
}

.h-n-link:hover {
  text-decoration: none;
  color: #81b441;
}

.b-n-link {
  color: white;
}

.b-n-link:hover {
  text-decoration: none;
  text-decoration: underline;
  color: #81b441;
  transition: text-decoration 0.3;
  -webkit-transition: text-decoration 0.3;
  -moz-transition: text-decoration 0.3;
  -ms-transition: text-decoration 0.3;
  -o-transition: text-decoration 0.3;
}

.stky-h-n-link {
  font-size: 1.8rem;
}

.stky-h-n-link:hover {
  color: #14a800 !important;
}

.carousel-control-next-icon {
  background: none !important;
}

.carousel-control-prev-icon {
  background: none !important;
}

.carousel-inner {
  /* position: relative; */
  /* width: 100%; */
  /* height: 50vh; */
  overflow: inherit !important;
}

.car1-content {
  /* text-align: right !important; */
  right: 10%;
}

.content__container {
  font-weight: 700;
  font-size: 96px !important;
  overflow: hidden;
  font-family: "Open Sans";
  height: 145px !important;
  padding: 0px 10px;
  margin-top: 0%;
  line-height: 1;
}

.MuiPaper-root {
  max-width: 100vw;
  max-height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
}

.bb-lgreen {
  border-bottom: 5px solid #b0ee63;
}

.p1 {
  font-size: 32px;
  font-family: "Open Sans";
}

.p2 {
  font-size: 30px;
  font-family: helvetica;
}

.p3 {
  /* font-size: 20px;
  font-weight: 600;
  font-family: "Open Sans"; */
  font: normal normal 600 20px/25px Open Sans;
}
.p3--15 {
  font: normal normal 600 15px/25px Open Sans;
}

.p4 {
  font: normal normal bold 34px/34px Open Sans;
}

.p5 {
  font: normal normal normal 22px/36px Helvetica;
  line-height: 179px;
}

.p6 {
  font: normal normal bold 24px/28px Open Sans;
  /* line-height: 33px; */
}

.services-btn,
.industies-btn {
  font-size: 20px;
}

.p7 {
  font: normal normal normal 30px/36px Helvetica;
}

.p8 {
  font: normal normal normal 18px/25px Helvetica;
}

.p9 {
  font: normal 50px/68px Open Sans;
  font-weight: 800;
  font-family: OpenSans-ExtraBold;
}
.p9-b {
  font: normal 50px/68px Open Sans;
  font-weight: 800;
  color: white;
  font-family: OpenSans-ExtraBold;
}
.p10 {
  font: normal normal bold 24px/33px Open Sans;
}

.p11 {
  font: normal normal normal 14px/19px Open Sans;
}

.p12 {
  font: normal normal normal 21px/25px Helvetica;
}

.p13 {
  font: normal normal bold 20px/25px Open Sans;
}

.p14 {
  font: normal normal bold 24px/29px Helvetica;
}
.p14-n {
  font: normal normal normal 24px/29px Helvetica;
}
.p15 {
  font: normal normal normal 20px/33px Helvetica;
}

.p16 {
  font: normal normal normal 16px/25px Open Sans;
}

.p17 {
  font: normal normal 800 24px/38px Open Sans;
}

.p18 {
  font: normal normal normal 20px/33px Helvetica !important;
}

.p19 {
  font: normal normal bold 30px/41px Open Sans;
}

.p20 {
  font: normal normal normal 17px/33px Helvetica;
  color: #ffffff;
  opacity: 0.8;
  line-height: 1.2;
}
.op-1 {
  opacity: 1 !important;
}
.p21 {
  font: normal normal bold 16px/25px Open Sans;
}

.p22 {
  font: normal normal normal 14px/25px Helvetica;
}

.p23 {
  font: normal normal bold 21px/30px Open Sans;
}

.p24 {
  font: normal normal 800 50px/68px Open Sans;
}

.p25 {
  font: normal normal bold 20px/30px Open Sans;
}

.p8-14-under:hover {
  text-decoration: underline !important;
}

.op-9 {
  opacity: 0.9;
}
.pt--80 {
  padding-top: 60px;
}
.br-5 {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.case-btn-h {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.service-img {
  width: 516px;
  height: 396px;
  border-radius: 10px;
}
.unilogo img {
  height: 200px;
}
.about-banner {
  height: 280px;
}
.expertise-banner {
  height: 350px;
}
.about-center {
  position: absolute;
  top: 50%;
  left: 16%;
  transform: translate(-16%, -50%);
}
.sticky-menu {
  top: 9% !important;
}

.contact-icon {
  width: 2vw;
}
.item__description-logo {
  max-width: 80px;
  max-height: 50px;
}
.exp-card-height {
  height: 230px;
}

.carousel-inner {
  /* height: 100%; */
}

.profile-icon {
  width: 2vw;
}

.bg-trans {
  background: transparent !important;
}

.no-shadow {
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
}

.MuiDialog-paperWidthSm {
  background: transparent !important;
}

.carousel-bg {
  padding: 100px 0px;
}

/*  Sidebar settings */

.sidebar-mb {
  right: 0%;
}

.djoRoX {
  background: #1d4354 !important;
  color: #fff;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009;
  right: -19%;
  top: -23%;
}

@media (max-width: 360px) {
  .contact-icon {
    width: 6vw;
  }
  .profile-icon {
    width: 6vw;
  }
  .font-14 {
    font-size: 0.7rem;
  }
  .car1-content {
    /* text-align: right !important; */
    right: -42%;
  }
}

.h-icon-auto {
  height: auto !important;
  margin-top: 0 !important;
}

.font-clr-blu {
  color: #1d4354;
}

.font-weight-100 {
  font-weight: 100;
}

.p47 {
  font: normal 47px/68px Open Sans;
  font-family: Open Sans;
}

.img-container:hover > svg > g > rect {
  fill: #1d4354;
}

.img-container:hover > svg > g > line {
  stroke: #fff;
}

.img-container:hover > .about-centered > svg > g > path {
  fill: #fff;
}

.img-container:hover > .about-centered1 > div {
  color: #fff !important;
  opacity: 1;
}
.img-container:hover > .about-centered1 > div i {
  color: #fff !important;
  opacity: 1;
}
.p36 {
  font: normal normal bold 36px/49px Open Sans;
}

.pr-10 {
  padding-right: 6% !important;
}

.p22 {
  font: normal normal normal 19px/30px Open Sans;
}

.br-39 {
  border-radius: 39px;
}

.pxx-xxl-5 {
  padding-right: 6rem !important;
  padding-left: 8rem !important;
}

.bg-light-grey {
  background-color: #f8f9fa;
}

.overflow {
  overflow: hidden;
}
.py--80 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.py--40 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.py--60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.pb--80 {
  padding-bottom: 60px;
}
.pxx-10 {
  padding-left: 7% !important;
  padding-right: 7% !important;
}
.py-xxl-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.my-xxxl-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.p-xl-resp {
  padding: 3.5rem !important;
}
.pxx-3 {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
.w-fit {
  width: fit-content;
}
.w-95 {
  width: 95%;
}
body {
  font-size: 18px !important;
}
/* p {
  font-size: 18px !important;
} */
.faq-head {
  font-size: 20px !important;
  font-weight: 400;
}
.opening-flex {
  display: flex !important;
}
.opening-padding {
  padding: 15px 10px !important;
}
.h-desktop {
  width: 38px;
}
@media screen and (max-width: 767px) {
  p {
    font-size: 16px !important;
  }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100% !important;
  padding-right: 50px !important;
  padding-left: 50px !important;
  margin-right: auto !important;
  margin-left: auto !important;
}
.company-card h4 {
  font: normal normal normal 22px/25px Helvetica;
}
.company-card h5 {
  font: normal normal bold 18px/30px Open Sans;
}
.company-card .parrot {
  font: normal normal bold 18px/30px Open Sans;
}
.serve-card {
  padding: 25px;
}
.serve-card .card-body {
  padding: 22px;
}
@media screen and (max-width: 1920px) {
  .exp-card-height {
    height: 235px;
  }
}
@media screen and (max-width: 1440px) {
  .exp-card-height {
    height: 235px;
  }
  .afterup h3 {
    font-size: 13px;
  }
}
@media screen and (max-width: 1366px) {
  .exp-card-height {
    height: 210px;
  }
}
@media screen and (max-width: 1024px) {
  .exp-card-height {
    height: 270px;
  }
}
@media screen and (max-width: 768px) {
  .company-card h4 {
    font: normal normal normal 14px/20px Helvetica;
  }
  .company-card h5 {
    font: normal normal bold 14px/19px Open Sans;
  }
  .company-card .parrot {
    font: normal normal bold 14px/18px Open Sans;
  }
  .serve-card .card-body {
    padding: 10px;
  }
  .serve-card {
    padding: 15px;
  }
  .contact-form {
    padding: 15px 0px;
  }
  .contact-form .contact-btn {
    margin-top: 30px;
  }
  .faq-head {
    font-size: 16px !important;
  }
  .floating-label {
    margin-top: 30px !important;
  }
  .about-center {
    left: 25%;
    transform: translate(-25%, -50%);
  }
  .gif-size {
    width: 100% !important;
  }
  .exp-card-height {
    height: 210px;
  }
  .web-solution .border-right {
    border-right: none !important;
  }
}

@media screen and (max-width: 485px) {
  .top-header {
    justify-content: unset;
    margin: 3px 0px;
  }
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    width: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .carousel-bg {
    padding: 50px 0px;
  }
  .serve-card {
    padding: 20px;
  }
  .contact-form {
    padding: 30px 0px;
  }
  .faq-head {
    font-size: 15px !important;
  }
  .h-desktop {
    width: 30px !important;
  }
  .paddimg-sm {
    padding: 0px !important;
  }
  .mbl-view {
    display: none !important;
  }
  .opening-flex {
    display: block !important;
  }
  .opening-padding {
    padding: 20px !important;
  }
  .opening-padding button {
    margin-top: 15px !important;
  }
  .web-solution {
    border-radius: 0px !important;
  }
}
