.nav-hdr-link:hover:before {
  left: 0;
  right: auto;
  width: 100%;
  font-weight: 600;
}

.nav-hdr-link:before {
  content: "";
  width: 0;
  right: 0;
  height: 5px;
  top: -15px;
  bottom: 0;
  background: #81b441;
  border-radius: 5px;
  position: absolute;
  -webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.nav-menu-link:hover:before {
  left: 0;
  right: auto;
  width: 100%;
  font-weight: 600;
}

.nav-menu-link:before {
  content: "";
  width: 0;
  right: 0;
  height: 5px;
  bottom: -15px;
  background: #81b441;
  border-radius: 5px;
  position: absolute;
  -webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.nav-menu-active:before {
  content: "";
  left: 0;
  right: auto;
  width: 100%;
  font-weight: 600;
  height: 5px;
  bottom: -15px;
  background: #81b441;
  border-radius: 5px;
  position: absolute;
  -webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.card-bb-animate:hover:before {
  left: 0;
  right: auto;
  width: 100%;
  font-weight: 600;
}

.card-bb-animate:before {
  content: "";
  width: 0;
  right: 0;
  height: 4px;
  bottom: 0%;
  background: #81b441;
  position: absolute;
  -webkit-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -moz-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -ms-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
}

.link-active:after {
  content: "";
  position: absolute;
  background-color: #78b94e;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  top: -15px;
  left: 0;
  /*border-top: 4px solid #81b441;
  color: #81b441;
  font-weight: 600;
  */
}
.border-after:after {
  content: "";
  position: absolute;
  background-color: white;
  width: 35%;
  height: 4px;
  bottom: 0;
  left: 1%;
  /* border-top: 4px solid #81b441;
    color: #81b441;
    font-weight: 600; */
}
