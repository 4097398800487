.img-container {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services__items .items .item .item__description {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  height: 100%;
}
.services__items .items .item .item__description > div {
  min-height: 120px;
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.services__items .items .item .item__link {
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: -100%;
  width: 100%;
  height: 100%;
}
.services__items .items .item .item__link .item__more {
  -js-display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-transform: uppercase;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}
.services__items .items .item .item__link .item__more i {
  margin-left: 0;
  -webkit-transition: margin-left 0.3s ease 0.5s;
  transition: margin-left 0.3s ease 0.5s;
}
.services__items .items .item:hover {
  background-color: rgba(20, 118, 242, 0.8);
  -webkit-transition: background-color ease-ina-out 0.3s;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}
.services__items .items .item:hover .item__description {
  opacity: 0;
  -webkit-animation: shrink 0.35s ease-in;
  animation: shrink 0.35s ease-in;
}
.services__items .items .item:hover .item__link {
  display: block;
  visibility: visible;
  bottom: 0;
  opacity: 1;
  -webkit-animation: grow 0.5s ease;
  animation: grow 0.5s ease;
}
.services__items .items .item:hover .item__link {
  display: block;
  visibility: visible;
  bottom: 0;
  opacity: 1;
  -webkit-animation: grow 0.5s ease;
  animation: grow 0.5s ease;
}
.bottom-right {
  position: absolute;
  bottom: 0;
  right: 4%;
  transform: translate(-50%, -50%);
}

.grey-image > img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}

.grey-image:hover > img {
  filter: none;
  -webkit-filter: none;
}

.bottom-right:hover > a {
  opacity: 0.7;
}

.bottom-right > a {
  color: #fff;
}

.about-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.about-left {
  left: 30%;
}

/* case study cards */

.card {
  box-shadow: 0px 3px 41px #1d43541a;
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.br-5 {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.card-d-para {
  /* height: 13vh; */
  font: normal normal normal 20px/33px Helvetica;
  letter-spacing: 0.52px;
  margin-top: 5%;
}

.overlay-box {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.overlay-device {
  width: 100%;
  justify-content: center;
}

.overlay-cloud {
  width: 100%;
  justify-content: center;
}

.card-d-btn:focus {
  border: none;
  outline: none;
}

@media (max-width: 1682px) {
  .card-d-para {
    height: 13vh;
  }
}

@media (max-width: 1605px) {
}

@media (max-width: 1445px) {
  .card-d-para {
    height: 20vh !important;
  }
}

@media (max-width: 1405px) {
  .card-d-para {
    height: 16vh !important;
  }
}

@media (max-width: 1399px) {
}

@media (max-width: 1370px) {
  .card-d-para {
    height: 20vh !important;
  }
}

@media (max-width: 1285px) {
  .card-d-para {
    height: 17vh !important;
  }
}

@media (max-width: 1024px) {
  .card-d {
    margin: auto;
    margin-top: 5% !important;
  }
  .card-d-para {
    height: 4vh !important;
  }
}

@media (max-width: 770px) {
  .card-d {
    margin: auto;
    margin-top: 5% !important;
  }
  .card-d-para {
    height: 4vh !important;
  }
}

@media (max-width: 415px) {
  .card-d {
    margin: auto;
    margin-top: 5% !important;
  }
  .card-d-para {
    height: 4vh !important;
  }
}

@media (max-width: 380px) {
  .card-d {
    margin: auto;
    margin-top: 5% !important;
  }
  .card-d-para {
    height: 25vh !important;
  }
  .carousel-inner {
    /* height: 44vh; */
  }
}

@media (max-width: 320px) {
  .card-d {
    margin: auto;
    margin-top: 5% !important;
  }
  .card-d-para {
    height: 4vh !important;
  }
}

.card-d-para {
  height: auto !important;
}
